import React from 'react';
import styles from './Who.module.css';

import larian from "../../images/לריאן לרמן.png"
const Who = () => {
  return (
    <div className={styles.aboutUs}>
      <h2 className={styles.title} id="מי אני">קצת עלי</h2>
      
      <div className={styles.founders}>
        <div className={styles.founder}>
          <h1 className={styles.founderName}>לריאן לרמן</h1>
          <img src={larian} alt="לריאן לרמן" className={styles.founderImage} />
        </div>
        {/* <div className={styles.founder}>
          <h1 className={styles.founderName}>יוגב לרמן</h1>
          <img src={yogev} alt="יוגב לרמן" className={styles.founderImage} />
        </div> */}
      </div>
          <p className={styles.description}>
          אני לריאן זוסינו לרמן,  נשואה ליוגב ואמא לאנליה ואלה-יה. 
          כשאנליה נולדה זכיתי לתואר ״אמא״ וזה מה שסלל עבורי את הדרך בה אני נמצאת היום, קיבלתי את הזכות להאיר ולאחד לבבות של הורים וילדים.
</p>

<p className={styles.description}>
כיום אני מדריכת הורים מוסמכת מכון אדלר, יועצת שינה לתינוקות ופעוטות בשיטת לילה טוב, מדריכה לעיסוי תינוקות וסטודנטית לליווי התפתחותי גילאי לידה עד שלוש שנים במכללת לריסה גינה .

</p>
<p className={styles.description}>
החיבור בין הורים וילדים ובניית מערכת היחסים בינהם זה הדבר המרתק ביותר עבורי, זו שליחות חיי ואני ברת מזל לחיות בידי יום את מה שאני הכי אוהבת לעשות.
</p>
<p className={styles.description}>
יש לי קליניקה בשכונת אחיסמך החדשה בה אני מלווה ומדריכה הורים ובנוסף במרכז ביתי אני מארחת הורים למעגלי הורות להורים שנמצאים עם תינוקם בבית ורוצים מרחב של יצירת אינטרקציות חברתיות עבורם ועבור תינוקם וגם מכלול של העשרת ידע והקניית כלים בתחום ההורות.
</p>
<h1 className={styles.title} id="הבית המארח">הכירו את: הבית המארח</h1>
{/* //אפשר לשים תמונה של הלוגו */}
      {/* <p className={styles.description}>
  מרכז פילינג הינו מקפצה להעלאת הערך והביטחון העצמי של הילדים בעיני עצמם.
  הפעילויות הספורטיביות החוויתיות משלבות בין יכולות מוטוריות קואורדינטיביות
  ליכולות ריגשתיות, תקשורתיות-חברתיות וקוגנטיביות המהוות מכלול להקניית כישורי
  חיים ומיומנויות חברתיות חשובים ביותר.
</p>

<p className={styles.description}>
  פעילות ספורטיבית חוויתית הינה "מקפצה" איכותית ויעילה ביותר בתחומים רבים
  ומגוונים, הרבה מעבר לשיפור היכולות המוטוריות.
  אין ספור מחקרים בשנים האחרונות כבר הוכיחו כי קיים קשר הדוק בין פעילות גופנית
  על תפקוד המוח, וסיוע לילדים בשכלול כישורי חיים נחוצים ביותר, כגון קבלת
  החלטות, פתרון בעיות, ומיומנויות חברתיות והצלחה בתחומי חיים שונים.
</p>

<p className={styles.description}>
  בפעילות ספורטיבית מתרחשים תהליכים חשיבתיים רבים – עיבוד מידע, קשב, זיכרון,
  תפיסה מרחבית, ציפייה וקבלת החלטות. כל אלו נחקרו בעשורים האחרונים, בעיקר
  בהקשר של משחקי כדור ומחבט, בהם השחקן פועל בסביבה דינמית: בכל רגע נתון,
  עליו לבחון ולזהות מרכיבים שונים בסביבתו המשתנה, ובעזרתם להתמודד עם
  משימות כמו פתרון בעיות במציאות של לחץ פסיכולוגי, לפרש פירוש מדויק של מה
  רלוונטי יותר, איזו תגובה היא הנכונה והמהירה ביותר לסיטואציה הנתונה.
</p>

<p className={styles.description}>
  בכל פעילות ספורטיבית על הילד לדעת לזהות ולזכור דפוסי משחק, לצפות את
  ההשלכות של כל פעולה אפשרית, לסרוק את מרחב הפעולה בצורה ממוקדת מטרה
  כדי לקבל החלטה מותאמת ומדויקת, להעריך מי נמצא בעמדה מאיימת יותר או פחות,
  לנתח את האופציות הקיימות, ולנבא מה כדאי לעשות כדי לסכל מהלכים וכו׳.
</p> */}

    </div>
  );
};

export default Who;